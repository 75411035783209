<template>
    <div>
        <template v-if="saleErrors.length > 0">
            <h4> Réservation </h4>

            <ul class="mb-4">
                <li v-for="error of saleErrors" :key="error.text"> {{ error.text }} </li>
            </ul>
        </template>

        <template v-if="contactsErrors.length > 0">
            <h4> Contacts </h4>

            <ul>
                <li v-for="error of contactsErrors" :key="error.text">
                    {{ error.text }}
                    <template v-if="error.type === 'mobile_format'">
                        <v-menu open-on-hover max-height="300" right offset-x>
                            <template v-slot:activator="{ on }">
                                <v-icon color="primary" x-small v-on="on"> fas fa-info-circle </v-icon>
                            </template>

                            <v-card>
                                <v-card-text>
                                    Le mobile doit être renseigné selon un format valide d'un des pays suivants : <br>
                                    <v-list dense>
                                        <v-list-item v-for="item of availableMobilePhones" :key="item.name">
                                            <v-list-item-action>
                                                <span :class="item.flag"></span>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.name }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ item.tips }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </template>
                </li>
            </ul>
        </template>

        <template v-if="documentsErrors.length > 0">
            <h4> Documents </h4>

            <ul>
                <li v-for="error of documentsErrors" :key="error.text"> {{ error.text }} </li>
            </ul>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        errors: { type: Array },
        documentsErrors: { type: Array },
        contactsErrors: { type: Array },
        saleErrors: { type: Array }
    },

    data: () => ({
        availableMobilePhones: [
            { name: 'France', flag: 'fi fi-fr', tips: '"06*,07*,+336*,+337*,00336*,00337*' },
            { name: 'DOM-TOM', flag: 'fi fi-fr', tips: '+590*,+594*,+596*,+262*,+687*,+689*,+681*,+508*' },
            { name: 'Suisse', flag: 'fi fi-ch', tips: '+417*,00417*' },
            { name: 'Luxembourg', flag: 'fi fi-lu', tips: '+352*,00352*' },
            { name: 'Allemagne', flag: 'fi fi-de', tips: '+49*,0049*' },
            { name: 'Belgique', flag: 'fi fi-be', tips: '+324*,00324*' },
            { name: 'Royaume-Uni, Irlande', flag: 'fi fi-gb', tips: '+447*,00447*,+3538*,003538*' },
            { name: 'Etats-Unis', flag: 'fi fi-us', tips: '+1*,001*' },
            { name: 'Portugal', flag: 'fi fi-pt', tips: '+3519*,003519*' },
            { name: 'Monaco', flag: 'fi fi-mc', tips: '+377*,00377*' },
            { name: 'Taïwan', flag: 'fi fi-tw', tips: '+8869*,008869*' },
            { name: 'Jordanie', flag: 'fi fi-jo', tips: '+9627*,009627*' },
            { name: 'Niger', flag: 'fi fi-ne', tips: '+227*,00227*' },
            { name: 'Emirats Arabes Unis', flag: 'fi fi-ae', tips: '+971*,00971*' },
            { name: 'Turquie', flag: 'fi fi-tr', tips: '+905*,00905*' },
            { name: 'Singapour', flag: 'fi fi-sg', tips: '+65*,0065*' },
            { name: 'Pays-bas', flag: 'fi fi-nl', tips: '+316*,00316*' },
            { name: 'Andorre', flag: 'fi fi-ad', tips: '+376*,00376*' },
            { name: 'Hong Kong', flag: 'fi fi-hk', tips: '+852*,00852' }
        ]
    })
};
</script>