<template>
    <v-card class="axessia-card">
        <v-toolbar class="toolbar-underline mb-2 mx-3" dense flat>
            <v-toolbar-title class="primary--text title">
                Documents
            </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-2">
            <v-row>
                <v-col cols="12" md="6">
                    <v-sheet class="rounded pa-4" color="grey lighten-4">
                        <h6 class="subtitle-1 primary--text pb-4">
                            Contrats

                            <UploadDialog v-if="canStartSignProcess(sale)" :sourceId="sale.id" :sourceType="100" code="CONTRACT_ATTACHMENTS" @reload="$emit('reload')">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="float-right" color="primary" x-small v-on="on">
                                        Ajouter
                                    </v-btn>
                                </template>
                            </UploadDialog>
                        </h6>

                        <Document :document="getDocument('CONTRACT')" :sale="sale" :documentValidation="getDocumentValidation(getDocument('CONTRACT').id)" @reload="$emit('reload')" />
                        <Document v-for="document of contract1" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />
                        <Document v-for="document of contract2" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />
                        <Document v-for="document of contract3" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />
                    </v-sheet>
                </v-col>

                <v-col cols="12" md="6">
                    <v-sheet class="rounded pa-4" color="grey lighten-4">
                        <h6 class="subtitle-1 primary--text pb-4"> Document clients </h6>

                        <Document v-for="document of clients1" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />
                        <Document v-for="document of clients2" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />

                        <span v-if="(clients1.length + clients2.length) === 0" class="font-italic">
                            Aucun document
                        </span>
                    </v-sheet>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-sheet class="rounded pa-4" color="grey lighten-4">
                        <h6 class="subtitle-1 primary--text pb-4"> Notification </h6>

                        <Document v-for="document of notification1" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />
                        <Document v-for="document of notification2" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />
                        <Document v-for="document of notification3" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" @reload="$emit('reload')" />

                        <span v-if="(notification1.length + notification2.length + notification3.length) === 0" class="font-italic">
                            Aucun document
                        </span>
                    </v-sheet>
                </v-col>

                <v-col v-if="!getConfig('sales.disable_other_documents', false)" cols="12" md="6">
                    <v-sheet class="rounded pa-4" color="grey lighten-4">
                        <h6 class="subtitle-1 primary--text pb-4">
                            Autres documents

                            <UploadDialog :sourceId="sale.id" :sourceType="100" code="OTHERS" @reload="$emit('reload')">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="float-right" color="primary" x-small v-on="on">
                                        Ajouter
                                    </v-btn>
                                </template>
                            </UploadDialog>
                        </h6>

                        <Document v-for="document of others" :key="`${document.id}-${document.code}`" :document="document" :sale="sale" :deletable="true" @reload="$emit('reload')" />

                        <span v-if="others.length === 0" class="font-italic">
                            Aucun document
                        </span>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import salesMixin from '../../mixins/sales.js';

import Document from './documents/Document.vue';
import UploadDialog from '../dialogs/UploadDialog.vue';

export default {
    name: 'DocumentsCard',

    mixins: [salesMixin],

    components: {
        Document,
        UploadDialog
    },

    props: {
        sale: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        documentValidations: []
    }),

    computed: {
        contract1() {
            return this.sale.documents.filter((d) => d.code === 'CONTRACT_ATTACHMENTS');
        },
        contract2() {
            return this.sale.documents.filter((d) => d.code.includes('CONTRACT_ATTACHMENTS_'));
        },

        contract3() {
            return this.sale.documents.filter((d) => d.code.includes('CONTRACT_OPTIONAL_'));
        },

        clients1() {
            return this.sale.documents.filter((d) => d.code.includes('CTR') && !d.code.includes('CTR_ID'));
        },
        clients2() {
            return this.sale.documents.filter((d) => d.code.includes('CTR_ID'));
        },

        notification1() {
            return this.sale.documents.filter((d) => d.code.includes('SEND_NOTIFICATION_ID_'));
        },
        notification2() {
            return this.sale.documents.filter((d) => d.code.includes('RECEIPT_NOTIFICATION_ID_'));
        },
        notification3() {
            return this.sale.documents.filter((d) => d.code.includes('SEND_LETTER_ID_'));
        },

        others() {
            return this.sale.documents.filter((d) => d.code === 'OTHERS');
        }
    },

    methods: {
        getDocument(code) {
            return this.sale.documents.find((d) => d.code === code);
        },

        getDocumentValidation(documentId) {
            return this.documentValidations.find((d) => d.keyid === documentId);
        },

        async fetchDocumentValidation() {
            const documentIds = this.sale.documents.map((d) => d.id).filter((d) => d !== '0');
            const { documents } = await this.repos.documentValidation.getValidations({ documents: documentIds, limit: 1000 });
            this.documentValidations = documents;
        }
    }
};
</script>
