<template>
    <v-card class="axessia-card">
        <v-toolbar class="toolbar-underline mb-2 mx-3" dense flat>
            <v-toolbar-title class="title primary--text">
                Acquisition
            </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-2">
            <v-row>
                <v-col cols="12" lg="8">
                    <v-row>
                        <!-- Products -->
                        <v-col cols="12">
                            <v-sheet color="rounded grey lighten-4 pa-4">
                                <h6 class="subtitle-1 primary--text"> Lots </h6>

                                <v-simple-table class="rounded-table">
                                    <template v-slot:default class="rounded">
                                        <thead>
                                            <tr>
                                                <th> Description </th>
                                                <th class="text-right"> Prix BDV </th>
                                                <th class="text-right"> Remise </th>
                                                <th class="text-right"> Prix négocié </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ProductRow v-for="product in primaryProducts" :key="product.id" :product="product" :vat="vat" :sale="sale" />
                                            <ProductRow v-for="product in secondaryProducts" :key="product.id" :product="product" :vat="vat" :sale="sale" />

                                            <tr class="grey lighten-2">
                                                <td> <span class="font-italic"> Totaux </span> </td>
                                                <td class="text-right"> {{ productsSum('initialPrice') | toCurrencyString() }} </td>
                                                <td class="text-right"> {{ discountSum | toCurrencyString() }} </td>
                                                <td class="text-right"> {{ productsSum('salePrice') | toCurrencyString() }} </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-sheet>
                        </v-col>

                        <!-- Deposits -->
                        <v-col cols="12">
                            <v-sheet color="rounded grey lighten-4 pa-4">
                                <h6 class="subtitle-1 primary--text">
                                    Dépôt de garantie

                                    <DepositsEditionDialog :saleId="sale.id" :initDeposits="sale.deposits" @reload="$emit('reload')">
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary" outlined class="float-right" :disabled="disableAcquisitionEdition" x-small v-on="on">
                                                Modifier
                                            </v-btn>
                                        </template>
                                    </DepositsEditionDialog>
                                </h6>

                                <v-simple-table class="rounded-table">
                                    <template v-slot:default class="rounded">
                                        <thead>
                                            <tr>
                                                <th> Etabli par </th>
                                                <th> Banque </th>
                                                <th> Type </th>
                                                <th> Numéro </th>
                                                <th class="text-right"> Montant </th>
                                                <th> Date </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="deposit of sale.deposits" :key="deposit.id">
                                                <td> {{ deposit.contact }} </td>
                                                <td> {{ deposit.bank }} </td>
                                                <td> {{ deposit.type === 0 ? 'Chèque' : '' }} {{ deposit.type === 1 ? 'Virement' : '' }} </td>
                                                <td> {{ deposit.number }} </td>
                                                <td class="text-right"> {{ deposit.amount | toCurrencyString() }} </td>
                                                <td> {{ deposit.date | toDate() }} </td>
                                            </tr>

                                            <tr v-if="sale.deposits.length === 0">
                                                <td colspan="10" class="text-center font-italic"> Aucun dépôt de garantie </td>
                                            </tr>

                                            <tr class="grey lighten-2">
                                                <td> <span class="font-italic"> Totaux </span> </td>
                                                <td> </td>
                                                <td> </td>
                                                <td> </td>
                                                <td class="text-right"> {{ depositSum | toCurrencyString() }} </td>
                                                <td> </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" lg="4">
                    <v-row>
                        <!-- Sale details -->
                        <v-col cols="12">
                            <v-sheet color="rounded grey lighten-4 pa-4">
                                <v-row>
                                    <v-col>
                                        <h6 class="subtitle-1 primary--text">
                                            Caractéristiques

                                            <CharacteristicDialog :initSale="sale" @reload="$emit('reload')">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="primary" :disabled="disableAcquisitionEdition" class="float-right" outlined x-small v-on="on">
                                                        Modifier
                                                    </v-btn>
                                                </template>
                                            </CharacteristicDialog>
                                        </h6>
                                    </v-col>
                                </v-row>

                                <v-sheet color="white" class="pa-2 rounded">
                                    <v-row align="center" class="mt-0">
                                        <v-col cols="6"> Taux TVA </v-col>
                                        <v-col cols="6">
                                            <template v-if="sale.vat || sale.vat === 0">
                                                {{ sale.vat }} %
                                            </template>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.sale_purpose.enabled', true)" align="center">
                                        <v-col cols="6"> {{ getConfig('sales.fields.sale_purpose.name', 'Destination du bien') }} </v-col>
                                        <v-col cols="6"> {{ sale.salepurpose }} </v-col>
                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.sale_type.enabled', true)" align="center">
                                        <v-col cols="6"> {{ getConfig('sales.fields.sale_type.name', 'Type d\'acquisition du bien') }} </v-col>
                                        <v-col cols="6"> {{ sale.saletype }} </v-col>

                                    </v-row>

                                    <v-row v-if="getConfig('sales.fields.sale_type.enabled', true) && isSalePinel(sale)">
                                        <v-col>
                                            <small>
                                                Le réservataire déclare avoir l'intention de demander le bénéfice du dispositif d'investissement locatif Pinel prévu par l'article 199 novovicies du code général des impôts
                                            </small>
                                        </v-col>
                                    </v-row>

                                    <template v-if="isSalePinel(sale)">
                                        <v-row align="center" class="my-1">
                                            <v-col cols="3">
                                                <hr class="py-0">
                                            </v-col>
                                        </v-row>

                                        <v-row align="center" class="mt-0">
                                            <v-col cols="6"> Dispositif Pinel </v-col>
                                            <v-col cols="6"> {{ sale.pinel.dispositifPinel === '1' ? 'Oui' : 'Non' }} </v-col>
                                        </v-row>

                                        <v-row align="center">
                                            <v-col cols="6"> Pourcentage </v-col>
                                            <v-col cols="6"> {{ sale.pinel.percentFraisPinel }} % </v-col>
                                        </v-row>

                                        <v-row align="center">
                                            <v-col cols="6"> Montant des frais </v-col>
                                            <v-col cols="6"> {{ sale.pinel.fraisPinel }} € </v-col>
                                        </v-row>
                                    </template>
                                </v-sheet>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Loans -->
            <v-row>
                <v-col cols="12">
                    <v-sheet color="rounded grey lighten-4 pa-4">
                        <h6 class="subtitle-1 primary--text">
                            Financement

                            <v-switch :input-value="sale.noLoan" class="d-inline-block mt-0 ml-2" @change="updateNoLoan()" label="Aucun prêt solicité" hide-details />

                            <LoansEditionDialog :saleId="sale.id" :initLoans="sale.loans" :natures="natures" :etablissements="etablissements" @reload="$emit('reload')">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" :disabled="disableAcquisitionEdition" class="float-right" outlined x-small v-on="on">
                                        Modifier
                                    </v-btn>
                                </template>
                            </LoansEditionDialog>
                        </h6>

                        <v-simple-table class="rounded-table">
                            <template v-slot:default class="rounded">
                                <thead>
                                    <tr>
                                        <th> Prêt principal </th>
                                        <th> Nature </th>
                                        <th> Établissement </th>
                                        <th> Durée </th>
                                        <th class="text-right"> Montant </th>
                                        <th> Taux </th>
                                        <th> Mensualité </th>
                                        <th> Date de dépôt des pièces </th>
                                        <th> Date d'accord de prêt </th>
                                        <th> Date d'édition de l’offre de prêt </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="loan of sale.loans" :key="loan.id">
                                        <td> {{ loan.main === true ? 'Oui' : '' }} {{ loan.main === false ? 'Non' : '' }} </td>
                                        <td> {{ loan.nature }} </td>
                                        <td> {{ loan.etablissement }} </td>
                                        <td>
                                            {{ loan.duration }}
                                            {{ loan.durationType === 0 ? 'mois' : '' }} {{ loan.durationType === 1 ? 'années' : '' }}
                                        </td>
                                        <td class="text-right"> {{ loan.amount | toCurrencyString(true) }} </td>
                                        <td> {{ loan.rate | round(2) }} % </td>
                                        <td> {{ loan.monthlyAmount | toCurrencyString(true) }} </td>
                                        <td> {{ loan.applicationDate | toDate() }} </td>
                                        <td> {{ loan.offerDate | toDate() }} </td>
                                        <td> {{ loan.availabilityDate | toDate() }} </td>
                                    </tr>

                                    <tr v-if="sale.loans.length === 0">
                                        <td colspan="10" class="text-center font-italic"> Aucun financement </td>
                                    </tr>

                                    <tr class="grey lighten-2">
                                        <td colspan="4"> <span class="font-italic"> Totaux </span> </td>
                                        <td class="text-right"> {{ loanSum | toCurrencyString(true) }} </td>
                                        <td colspan="5"> </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import salesMixin from '../../mixins/sales.js';
import ProductRow from './acquisition/ProductRow.vue';
import LoansEditionDialog from './acquisition/LoansEditionDialog.vue';
import CharacteristicDialog from './acquisition/CharacteristicEditionDialog.vue';
import DepositsEditionDialog from './acquisition/DepositsEditionDialog.vue';

export default {
    name: 'AcquisitionCard',

    mixins: [salesMixin],

    components: {
        ProductRow,
        LoansEditionDialog,
        CharacteristicDialog,
        DepositsEditionDialog
    },

    props: {
        sale: { type: Object, required: true },
        etablissements: { type: Array, default: () => ([]) },
        natures: { type: Array, default: () => ([]) }
    },

    computed: {
        vat() {
            let vat = parseFloat(this.sale.products.vat);
            if (isNaN(vat)) {
                vat = 0;
            }
            return vat;
        },

        primaryProducts() {
            return this.sale.products.filter((p) => p.main === 1);
        },
        secondaryProducts() {
            return this.sale.products.filter((p) => p.main !== 1);
        },

        depositSum() {
            return this.sale.deposits.reduce((sum, deposit) => {
                let amount = 0;
                if (typeof amount === 'number' && !isNaN(amount)) {
                    amount = deposit.amount;
                }
                return sum + amount;
            }, 0);
        },

        loanSum() {
            return this.sale.loans.reduce((sum, loan) => {
                let amount = 0;
                if (typeof amount === 'number' && !isNaN(amount)) {
                    amount = loan.amount;
                }
                return sum + amount;
            }, 0);
        },

        discountSum() {
            return this.sale.products.reduce((sum, product) => {
                return sum + parseFloat(product.initialPrice) - parseFloat(product.salePrice);
            }, 0);
        },

        disableAcquisitionEdition() {
            return this.sale.status !== 'prereserved';
        }
    },

    methods: {
        productsSum(column, horsTaxe = false) {
            const sum = this.sale.products.reduce((sum, product) => {
                sum += parseFloat(product[column]);
                return sum;
            }, 0);
            if (horsTaxe) {
                return sum * (1 - (this.vat / 100));
            } else {
                return sum;
            }
        },
        async updateNoLoan() {
            try {
                this.setLoading(true);

                let noLoan;
                if (this.sale.noLoan) {
                    noLoan = false;
                } else {
                    noLoan = true;
                }


                const body = {
                    sale: {
                        noLoan
                    }
                };

                const { success, err } = await this.repos.sales.updateSale(this.sale.id, body);
                if (success) {
                    this.$emit('reload');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: err.message,
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.rounded-table::v-deep .v-data-table__wrapper {
    border-radius: 4px;
}
</style>