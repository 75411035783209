<template>
    <v-hover v-slot="{ hover }">
        <v-alert class="pa-1" :color="color" border="left" elevation="1" colored-border dense>
            <v-row align="center">
                <v-col cols="11">
                    <template v-if="isEmpty">
                        <template v-if="isNoticeDescritive">
                            <UploadDialog :sourceId="sale.id" :sourceType="100" :code="document.code" :title="title" :allowTitleEdition="false" @reload="$emit('reload')">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn :color="color" class="ml-4" icon v-on="{ ...dialog, ...tooltip }">
                                                <v-icon> fas fa-file-pdf </v-icon>
                                            </v-btn>
                                        </template>
                                        Vous devez uploader l'une des notices descriptives du programme
                                    </v-tooltip>
                                </template>
                            </UploadDialog>
                        </template>
                        <template v-else-if="!isUploadAllowed">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn :color="color" class="ml-4" icon v-on="on">
                                        <v-icon> fas fa-file-pdf </v-icon>
                                    </v-btn>
                                </template>
                                {{ uploadNotAllowedReason }}
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <UploadDialog :sourceId="sale.id" :sourceType="100" :code="document.code" :title="title" :allowTitleEdition="false" @reload="$emit('reload')">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn :color="color" class="ml-4" icon v-on="{ ...dialog, ...tooltip }">
                                                <v-icon> fas fa-file-pdf </v-icon>
                                            </v-btn>
                                        </template>
                                        Ajouter le document
                                    </v-tooltip>
                                </template>
                            </UploadDialog>
                        </template>
                    </template>
                    <template v-else>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn :href="documentUrl" target="_blank" :color="color" class="ml-4" icon v-on="on">
                                    <v-icon> fas fa-file-pdf </v-icon>
                                </v-btn>
                            </template>
                            Visualiser le document
                        </v-tooltip>
                    </template>

                    <span>
                        {{ title }}
                    </span>
                </v-col>

                <v-col v-if="!isEmpty">
                    <template v-if="deletable">
                        <DeleteDocumentDialog :documentId="parseInt(document.id)" :sourceId="sale.id" :sourceType="parseInt(document.sourcetype)" :secured="document.secured === '1'" :title="title" @reload="$emit('reload')">
                            <template v-slot:activator="{ on: dialog }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn v-show="hover" class="float-right" color="red" icon small v-on="{ ...dialog, ...tooltip }">
                                            <v-icon small> fas fa-trash </v-icon>
                                        </v-btn>
                                    </template>
                                    Supprimer ce document
                                </v-tooltip>
                            </template>
                        </DeleteDocumentDialog>
                    </template>
                    <template v-else>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-show="hover" class="float-right" color="grey" icon small v-on="on">
                                    <v-icon small> fas fa-trash </v-icon>
                                </v-btn>
                            </template>
                            {{ notDeletableReason }}
                        </v-tooltip>
                    </template>
                </v-col>
            </v-row>
        </v-alert>
    </v-hover>
</template>

<script>
import salesMixin from '../../../mixins/sales.js';

import UploadDialog from '../../dialogs/UploadDialog.vue';
import DeleteDocumentDialog from '../../dialogs/DeleteDocumentDialog.vue';

export default {
    name: 'Document',

    mixins: [salesMixin],

    props: {
        sale: { type: Object, required: true },
        document: { type: Object, required: true }
    },

    components: {
        UploadDialog,
        DeleteDocumentDialog
    },

    computed: {
        isEmpty() {
            let isEmpty = this.document.id === '0';
            if (this.isNoticeDescritive && parseInt(this.document.count) > 1 && this.document.sourcetype === '1') {
                isEmpty = true;
            }
            return isEmpty;
        },

        title() {
            if (this.isNoticeDescritive) {
                return 'Notice Descriptive';
            } else {
                return this.document.title;
            }
        },

        isNoticeDescritive() {
            return this.document.code === 'CONTRACT_ATTACHMENTS_NOTICE';
        },

        documentUrl() {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/documents/${this.document.id}`;
        },

        color() {
            if (this.isEmpty) {
                if (this.document.iseasyfolder === '1' && !this.isNoticeDescritive) {
                    return 'orange lighten-2';
                } else {
                    return 'grey ligten-1';
                }
            } else {
                return 'blue';
            }
        },

        isUploadAllowed() {
            let allowed = true;

            if (this.document.iseasyfolder === '1') {
                return false;
            }

            if (this.document.code === 'CONTRACT') {
                return false;
            }

            return allowed;
        },

        uploadNotAllowedReason() {
            let reason = 'Dépot manuel non autorisé';

            return reason;
        },

        deletable() {
            let deletable = false;

            if (!this.isEmpty && this.document.sourcetype === '100') {
                if (this.sale.status === 'prereserved') {
                    deletable = true;
                } else {
                    if (this.document.code === 'OTHERS') {
                        deletable = true;
                    }
                }
            }

            if (!this.document.isCreatedByPartner && this.isDocumentOfClientsCategory(this.document)) {
                return false;
            }

            return deletable;
        },

        notDeletableReason() {
            let reason = 'Suppression impossible';

            if (this.sale.status !== 'prereserved') {
                reason = 'Vous ne pouvez plus supprimer de document sur cette réservation';
            }

            if (this.document.sourcetype === '1') {
                reason = 'Vous ne pouvez pas supprimer un document programme';
            }

            if (this.document.sourcetype === '2') {
                reason = 'Vous ne pouvez pas supprimer un document de lot';
            }

            if (!this.document.isCreatedByPartner && this.isDocumentOfClientsCategory(this.document)) {
                reason = 'Vous ne pouvez pas supprimer un document client que vous n\'avez pas uploadé vous-même';
            }

            if (this.document.code === 'CONTRACT') {
                reason = 'Dépôt manuel non autorisé';
            }

            return reason;
        }
    }
};
</script>