<template>
    <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <template v-if="getSignatureFromBuyer(sale, sale.buyers[0])">
                    {{ getSignatureFromBuyer(sale, sale.buyers[0]).signed | toDate() }}
                </template>
                <template v-else>
                    --/--/----
                </template>
            </span>
        </template>

        <v-list>
            <v-list-item v-for="buyer of sale.buyers" :key="`${buyer.id}-${buyer.type}`">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ buyer.name }} {{ buyer.firstname }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        <template v-if="getSignatureFromBuyer(sale, buyer)">
                            {{ getSignatureFromBuyer(sale, buyer).signed | toDate() }}
                        </template>
                        <template v-else>
                            --/--/----
                        </template>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item v-for="signature of getSaleBuyersSignatures(sale)" :key="`${signature.userId}-${signature.userType}`">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ getBuyerFromSignature(sale, signature).name }}
                        {{ getBuyerFromSignature(sale, signature).firstname }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        {{ signature.signed | toDate() }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->
        </v-list>
    </v-menu>
</template>

<script>
import salesMixin from '../../../mixins/sales.js';

export default {
    name: 'BuyersSignedSignatures',

    mixins: [salesMixin],

    props: {
        sale: { type: Object, required: true }
    }
};
</script>