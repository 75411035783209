<template>
    <v-dialog v-model="isOpen" width="70%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
             <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Edition dépôt de garantie
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-card-text class="pt-4">
                <ValidationObserver ref="observer">
                    <v-simple-table class="rounded-table">
                        <template v-slot:default class="rounded">
                            <thead>
                                <tr>
                                    <th style="width: 16.6%"> Etabli par </th>
                                    <th style="width: 16.6%"> Banque </th>
                                    <th style="width: 16.6%"> Type * </th>
                                    <th style="width: 16.6%"> Numéro </th>
                                    <th style="width: 16.6%"> Montant * </th>
                                    <th style="width: 16.6%"> Date </th>
                                    <th style="width: 1%"> </th>
                                </tr>
                            </thead>

                            <tbody class="no-hover">
                                <tr v-for="(deposit, i) of deposits" :key="deposit.id" style="position: relative">
                                    <td>
                                        <v-text-field v-model="deposit.contact" placeholder="Etali par" hide-details outlined dense />
                                    </td>

                                    <td>
                                        <v-text-field v-model="deposit.bank" placeholder="Banque" hide-details outlined dense />
                                    </td>

                                    <td>
                                        <v-select v-model="deposit.type" :items="[{ value: 0, text: 'Chèque' }, { value: 1, text: 'Virement' }]" placeholder="Type" clear-icon="far fa-times-circle" hide-details outlined clearable dense />
                                    </td>

                                    <td>
                                        <v-text-field v-model="deposit.number" placeholder="Numéro" hide-details outlined dense />
                                    </td>

                                    <td>
                                        <ValidationProvider v-slot="{ errors }" name="Montant" rules="required">
                                            <v-text-field v-model="deposit.amount" placeholder="Montant" hide-details outlined dense>
                                                <template v-slot:prepend-inner>
                                                    <v-tooltip top v-if="errors.length > 0">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon color="red" v-on="on"> fas fa-exclamation-circle </v-icon>
                                                        </template>
                                                        {{ errors[0] }}
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </td>

                                    <td>
                                        <DatePickerMenu v-model="deposit.date" :disabled="getConfig('sales.disable_deposit_date', false)" />
                                    </td>

                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on">
                                                    <v-icon @click="removeItem(deposit, i)"> far fa-times-circle </v-icon>
                                                </v-btn>
                                            </template>
                                            Supprimer ce dépôt de garantie
                                        </v-tooltip>

                                        <v-overlay :value="deposit.remove === true" absolute opacity="0.8" class="rounded">
                                            <h4>
                                                Dépôt de garantie supprimé
                                                <v-btn @click="deposit.remove = false" class="ml-4" color="red darken-4" small> Annuler </v-btn>
                                            </h4>
                                        </v-overlay>
                                    </td>
                                </tr>

                                <tr v-if="deposits.length === 0">
                                    <td colspan="7" class="text-center">
                                        Auncun dépôt de garantie
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn depressed @click="addNewItem()" small v-on="on">
                                                    <v-icon x-small left> fas fa-plus </v-icon>
                                                    Ajouter
                                                </v-btn>
                                            </template>
                                            Ajouter un dépôt de garantie
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePickerMenu from '../../widgets/DatePickerMenu.vue';

export default {
    name: 'DepositsEditionDialog',

    components: {
        DatePickerMenu
    },

    props: {
        saleId: { type: Number, required: true },
        initDeposits: { type: Array, required: true }
    },

    data: () => ({
        isOpen: false,
        deposits: []
    }),

    methods: {
        addNewItem() {
            this.deposits.push({});
        },

        removeItem(deposit, i) {
            if (deposit.id) {
                deposit.remove = true;
            } else {
                this.deposits.splice(i, 1);
            }
        },

        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = {
                    deposits: this.deposits
                };

                const { success, err } = await this.repos.sales.updateSale(this.saleId, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('reload');
                } else {
                    if (err) {
                        console.error(err);
                        throw new Error();
                    } else {
                        // 
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la mise à jour des dépôts de garantie',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.deposits = JSON.parse(JSON.stringify(this.initDeposits));
                this.deposits.forEach((d) => this.$set(d, 'remove', false));
            }
        }
    }
};
</script>

<style lang="scss">
tbody.no-hover {
    tr:hover {
        background-color: transparent !important;
    }
}
</style>
