<template>
    <v-container :class="{ 'fill-height': saleNotFound }">
        <template v-if="sale !== null">
            <v-row>
                <v-col>
                    <SynthesisCard :sale="sale" @reload="fetchSale(true)" />
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-tabs v-model="tab" fixed-tabs>
                        <v-tab to="#acquisition" replace>
                            <v-icon left> fas fa-building </v-icon>
                            Acquisition
                        </v-tab>

                        <v-tab to="#acquereurs" replace>
                            <v-icon left> fas fa-user </v-icon>
                            Acquéreurs
                        </v-tab>

                        <v-tab to="#documents" replace>
                            <v-icon left> fas fa-file-alt </v-icon>
                            Documents
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-tabs-items v-model="tab" :class="{ 'elevation-2': tab !== 'acquereurs' }">
                        <v-tab-item value="acquisition">
                            <AcquisitionCard :sale="sale" :natures="natures" :etablissements="etablissements" @reload="fetchSale(true)" />
                        </v-tab-item>

                        <v-tab-item value="acquereurs">
                            <v-row>
                                <v-col cols="12" lg="6" v-for="buyer of sale.buyers" :key="buyer.id + '_' + buyer.type">
                                    <BuyerCard :sale="sale" :buyer="buyer" @reload="fetchSale(true)" />
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item value="documents">
                            <DocumentsCard :sale="sale" @reload="fetchSale(true)" />
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </template>

        <template v-if="saleNotFound">
            <v-row justify="center" align="center">
                <v-col class="text-center">
                    <h2>
                        Cette vente n'existe pas ou à été supprimé
                    </h2>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import BuyerCard from '../components/sale/BuyerCard.vue';
import DocumentsCard from '../components/sale/DocumentsCard.vue';
import SynthesisCard from '../components/sale/SynthesisCard.vue';
import AcquisitionCard from '../components/sale/AcquisitionCard.vue';

export default {
    name: 'Sale',

    components: {
        BuyerCard,
        DocumentsCard,
        SynthesisCard,
        AcquisitionCard
    },

    data: () => ({
        sale: null,
        saleNotFound: false,
        etablissements: [],
        natures: [],
        tab: 'acquisition'
    }),

    methods: {
        async fetchSale(isReload) {
            try {
                this.setLoading(true);

                const query = {
                    include: 'buyers,products,deposits,loans,signatures,documents',
                    loanSummary: '1'
                };
                const { sale, natures, etablissements, err } = await this.repos.sales.getSale(this.$route.params.saleId, query);
                if (err) {
                    throw new Error(err);
                } else if (sale === null) {
                    this.saleNotFound = true;
                } else {
                    this.sale = sale;
                    this.natures = natures;
                    this.etablissements = etablissements;
                    if (isReload) {
                        this.$notify({
                            title: 'Information',
                            text: 'Les modifications ont été appliquées',
                            type: 'success'
                        });
                    }
                    if (!this.$route.hash) {
                        this.$router.replace(`#${this.tab}`);
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    created() {
        this.fetchSale(false);
        const codes = ['PVD_AMENAGEUR', 'SALE_SALEPURPOSE', 'SALE_SALETYPE', 'CTR_SITUATION_FAMILLE', 'CTR_ENFANTS', 'CTR_CSP'];
        this.$store.dispatch('parameters/fetchParameters', codes);
    }
};
</script>
