<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" small v-on="on">
                <v-icon small left> fas fa-file-signature </v-icon>
                Relancer le contrat
            </v-btn>
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Relancement du process de signature
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <template v-if="isCheckOk">
                    Êtes-vous sûr de vouloir relancer le process de signature ?
                </template>
                <template v-else>
                    <ErrorsDisplay :errors="errors" :documentsErrors="documentsErrors" :contactsErrors="contactsErrors" :saleErrors="saleErrors" />
                </template>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" :disabled="!isCheckOk" color="primary" small> Relancer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
import ErrorsDisplay from './ProcessCheck/ErrorsDisplay.vue';
import salesMixin from '../../../mixins/sales.js';

export default {
    name: 'RestartContractBtn',

    mixins: [salesMixin],

    components: {
        ErrorsDisplay
    },

    props: {
        sale: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false,
        errors: [],
        documentsErrors: [],
        contactsErrors: [],
        saleErrors: []
    }),

    computed: {
        isCheckOk() {
            return this.errors.length === 0;
        }
    },

    watch: {
        isOpen() {
            if (!this.isOpen) {
                return;
            }

            try {
                const { errors, saleErrors, contactsErrors, documentsErrors } = this.signatureProcessChecks(this.sale);
                this.errors = errors;
                this.saleErrors = saleErrors;
                this.contactsErrors = contactsErrors;
                this.documentsErrors = documentsErrors;
            } catch (err) {
                this.errors.push(0);
                this.isOpen = false;
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la validation de la réservation',
                    type: 'error'
                });
            }
        }
    },

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const { success, err } = await this.repos.sales.restartSignatureProcess(this.sale.id);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Information',
                        text: 'Le process de signature a bien été relancé',
                        type: 'success'
                    });
                    this.$emit('reload');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du relancement du process de signature',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>