<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn color="primary" small v-on="on">
                <v-icon small left> fas fa-file-signature </v-icon>
                Générer le contrat
            </v-btn>
        </template>

        <v-list>
            <OpenContractCheck :sale="sale" code="contrat_test_papier">
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        Visualiser un exemplaire temporaire du contrat (version papier)
                    </v-list-item>
                </template>
            </OpenContractCheck>

            <OpenContractCheck :sale="sale" code="contrat_test_numerique">
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        Visualiser un exemplaire temporaire du contrat (signature électronique)
                    </v-list-item>
                </template>
            </OpenContractCheck>

            <OpenContractCheck :sale="sale" code="contrat">
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        Visualiser la version définitive du contrat
                    </v-list-item>
                </template>
            </OpenContractCheck>

            <SignatureProcessCheck :sale="sale" @reload="$emit('reload')">
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        Lancer le process de signature électronique
                    </v-list-item>
                </template>
            </SignatureProcessCheck>
        </v-list>
    </v-menu>
</template>

<script>
import OpenContractCheck from './ProcessCheck/OpenContractCheck.vue';
import SignatureProcessCheck from './ProcessCheck/SignatureProcessCheck.vue';

export default {
    name: 'GenerateContractBtn',

    components: {
        OpenContractCheck,
        SignatureProcessCheck
    },

    props: {
        sale: { type: Object, required: true }
    }
};
</script>