<template>
    <v-card class="axessia-card">
        <v-toolbar class="toolbar-underline mb-2 mx-3" dense flat>
            <v-toolbar-title class="primary--text title">
                Synthèse
            </v-toolbar-title>

            <v-spacer />

            <GenerateContractBtn v-if="canStartSignProcess(sale)" :sale="sale" @reload="$emit('reload')" />

            <RestartContractBtn v-if="canRestartSignProcess(sale)" :sale="sale" @reload="$emit('reload')" />
        </v-toolbar>

        <v-card-text class="pt-2">
            <!-- Program and Contacts -->
            <v-row class="mx-2" align="center">
                <!-- Contacts -->
                <v-col cols="12" md="6">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <h6 class="subtitle-2 grey--text text--darken-2 d-inline-block mr-4" v-on="on"> Acquéreurs </h6>
                                </template>
                                <span> Les noms et coordonnées des différents acquéreurs </span>
                            </v-tooltip>
                        </v-col>

                        <v-col>
                            <BuyerChip v-for="(buyer, i) of sale.buyers" :key="i" :buyer="buyer" />
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Program -->
                <v-col cols="12" md="6">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <h6 class="subtitle-2 grey--text text--darken-2 d-inline-block mr-4" v-on="on">
                                        Programme
                                    </h6>
                                </template>
                                <span> Le programme et la ville concernés </span>
                            </v-tooltip>
                        </v-col>

                        <v-col>
                            <v-chip :to="`/programmes/${sale.program.id}`" class="mr-2" label small>
                                <v-icon left> fas fa-city </v-icon>
                                {{ sale.program.title }} -
                                <template v-if="sale.program.city"> {{ sale.program.city }} </template>
                                <template v-else>
                                    <span class="font-italic"> Ville non renseignée </span>
                                </template>
                            </v-chip>

                            <v-tooltip v-if="sale.program.presaledate" top>
                                <template v-slot:activator="{ on }">
                                    <v-chip color="transparent" label small v-on="on">
                                        {{ sale.program.presaledate | toDate() }}
                                    </v-chip>
                                </template>
                                Date d'actabilité
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Products and Dates-->
            <v-row class="mx-2">
                <!-- Status -->
                <v-col cols="12" md="6">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <h6 class="subtitle-2 grey--text text--darken-2 d-inline-block mr-4"> Statut </h6>
                        </v-col>

                        <v-col>
                            <v-tooltip :disabled="!sale.statusdatelabel" top>
                                <template v-slot:activator="{ on }">
                                    <v-chip :color="getSaleStatus(sale.status, 'color')" label small dark v-on="on">
                                        <v-icon left> {{ getSaleStatus(sale.status, 'icon') }} </v-icon>

                                        {{ getSaleStatus(sale.status, 'label') }}
                                    </v-chip>
                                </template>
                                {{ sale.statusdatelabel }}: {{ sale.statusdate | toDate() }}
                            </v-tooltip>

                            <v-tooltip v-for="badge of getSaleBadges(sale)" :key="badge.code" top>
                                <template v-slot:activator="{ on }">
                                    <v-chip :color="badge.color" label small dark class="ml-2" v-on="on">
                                        {{ badge.label }}
                                    </v-chip>
                                </template>
                                {{ badge.tooltip }}
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Products -->
                <v-col cols="12" md="6">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <h6 class="subtitle-2 grey--text text--darken-2 d-inline-block mr-4" v-on="on"> Acquisition </h6>
                                </template>
                                <span> Les lots concernés par la réservation </span>
                            </v-tooltip>
                        </v-col>

                        <v-col>
                            <ProductChip v-for="product of sale.products" :key="product.id" :product="product" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Dates -->
            <v-row class="mx-2">
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="subtitle-2 grey--text text--darken-2 pl-0"> Pré-réservation </th>
                                    <th class="subtitle-2 grey--text text--darken-2"> Signature acquéreur </th>
                                    <th class="subtitle-2 grey--text text--darken-2"> Signature promoteur </th>
                                    <th class="subtitle-2 grey--text text--darken-2"> Date envoi SRU </th>
                                    <th class="subtitle-2 grey--text text--darken-2"> Date limite rétractation </th>
                                    <th class="subtitle-2 grey--text text--darken-2"> Signature définitive avant </th>
                                    <th class="subtitle-2 grey--text text--darken-2"> Acte authentique </th>
                                    <th class="subtitle-2 grey--text text--darken-2"> Date de livraison </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td class="pl-0"> {{ sale.date | toDate() }} </td>
                                    <td>
                                        <BuyersSignedSignatures :sale="sale" />
                                    </td>
                                    <td> {{ getSalePromoterSignatureDate(sale) | toDate() }} </td>
                                    <td>
                                        <BuyersSRUSent :sale="sale" />
                                    </td>
                                    <td> {{ sale.retractationlimitdate | toDate() }} </td>
                                    <td> {{ sale.notarylimitdate | toDate() }} </td>
                                    <td> {{ sale.deeddate | toDate() }} </td>
                                    <td> {{ sale.deliverydate2 | toDate() }} </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import salesMixin from '../../mixins/sales.js';
import BuyerChip from './synthesis/BuyerChip.vue';
import ProductChip from './synthesis/ProductChip.vue';
import BuyersSRUSent from './synthesis/BuyersSRUSent.vue';
import RestartContractBtn from './synthesis/RestartContractBtn.vue';
import GenerateContractBtn from './synthesis/GenerateContractBtn.vue';
import BuyersSignedSignatures from './synthesis/BuyersSignedSignatures.vue';

export default {
    name: 'SynthesisCard',

    props: {
        sale: { type: Object, required: true }
    },

    mixins: [salesMixin],

    components: {
        BuyerChip,
        ProductChip,
        BuyersSRUSent,
        RestartContractBtn,
        GenerateContractBtn,
        BuyersSignedSignatures
    }
};
</script>