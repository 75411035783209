<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Visualisation du contrat
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <ErrorsDisplay :errors="errors" :documentsErrors="documentsErrors" :contactsErrors="contactsErrors" :saleErrors="saleErrors"  />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Ouvrir </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ErrorsDisplay from './ErrorsDisplay.vue';
import salesMixin from '../../../../mixins/sales.js';

export default {
    name: 'OpenContractCheck',

    mixins: [salesMixin],

    components: {
        ErrorsDisplay
    },

    props: {
        sale: { type: Object, required: true },
        code: { type: String, required: true }
    },

    data: () => ({
        isOpen: false,
        errors: [],
        documentsErrors: [],
        contactsErrors: [],
        saleErrors: []
    }),

    methods: {
        async submit() {
            window.open(this.contractUrl, '_blank');
        }
    },

    computed: {
        isCheckOk() {
            return this.errors.length === 0;
        },

        contractUrl() {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/documents/generated?&saleId=${this.sale.id}&code=${this.code}`;
        }
    },

    watch: {
        isOpen() {
            if (!this.isOpen) {
                return;
            }

            try {
                const { errors, saleErrors, contactsErrors, documentsErrors } = this.signatureProcessChecks(this.sale);
                this.errors = errors;
                this.saleErrors = saleErrors;
                this.contactsErrors = contactsErrors;
                this.documentsErrors = documentsErrors;

                if (this.isCheckOk) {
                    this.$nextTick(() => {
                        this.isOpen = false;
                    });
                    this.submit();
                }
            } catch (err) {
                this.errors.push(0);
                this.isOpen = false;
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la validation de la réservation',
                    type: 'error'
                });
            }
        }
    }
};
</script>
