<template>
    <v-dialog v-model="isOpen" width="90%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Edition financement
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <ValidationObserver ref="observer">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th> Prêt principal * / Nature * </th>
                                    <th> Établissement / Durée </th>
                                    <th> Montant * / Taux  </th>
                                    <th> Mensualité / Date de dépôt des pièces </th>
                                    <th> Date d'accord de prêt / Date d'édition de l’offre de prêt </th>
                                    <th> </th>
                                </tr>
                            </thead>

                            <tbody class="no-hover">
                                <tr v-for="(loan, i) of loans" :key="loan.id" style="position: relative">
                                    <td class="py-2">
                                        <v-select v-model="loan.main" class="mb-2" :items="[{ value: true, text: 'Oui' }, { value: false, text: 'Non' }]" placeholder="Principal" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined clearable dense />
                                        <v-select v-model="loan.nature" :items="natures" placeholder="Nature" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined clearable dense />
                                    </td>

                                    <td class="py-2">
                                        <!-- <v-text-field v-model="loan.etablissement" class="mb-2" placeholder="Établissement" hide-details outlined dense /> -->
                                        <v-select v-model="loan.etablissement" class="mb-2" :items="etablissements" placeholder="Établissement" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined clearable dense />
                                        <v-row no-gutters>
                                            <v-col class="mr-2">
                                                <v-text-field v-model="loan.duration" type="number" placeholder="Durée" hide-details outlined dense />
                                            </v-col>

                                            <v-col>
                                                <v-select v-model="loan.durationType" :items="[{ value: 0, text: 'Mois' }, { value: 1, text: 'Années' }]" placeholder="Type durée" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined clearable dense />
                                            </v-col>
                                        </v-row>
                                    </td>

                                    <td class="py-2">
                                        <v-text-field v-model="loan.amount" type="number" class="mb-2" placeholder="Montant" hide-details outlined dense />
                                        <v-text-field v-model="loan.rate" type="number" placeholder="Taux" hide-details outlined dense />
                                    </td>

                                    <td class="py-2">
                                        <v-text-field v-model="loan.monthlyAmount" type="number" class="mb-2" placeholder="Mensualité" hide-details outlined dense />
                                        <DatePickerMenu v-model="loan.applicationDate" />
                                    </td>

                                    <td class="py-2">
                                        <div class="mb-2">
                                            <DatePickerMenu v-model="loan.offerDate" />
                                        </div>
                                        <DatePickerMenu v-model="loan.availabilityDate" />
                                    </td>

                                    <td class="py-2">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on">
                                                    <v-icon @click="removeItem(loan, i)"> far fa-times-circle </v-icon>
                                                </v-btn>
                                            </template>
                                            Supprimer ce prêt
                                        </v-tooltip>

                                        <v-overlay :value="loan.remove === true" absolute opacity="0.8" class="rounded">
                                            <h4>
                                                Prêt supprimé
                                                <v-btn @click="loan.remove = false" class="ml-4" color="red darken-4" small> Annuler </v-btn>
                                            </h4>
                                        </v-overlay>
                                    </td>
                                </tr>

                                <tr v-if="loans.length === 0">
                                    <td colspan="7" class="text-center">
                                        Auncun prêt
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn depressed @click="addNewItem()" small v-on="on">
                                                    <v-icon x-small left> fas fa-plus </v-icon>
                                                    Ajouter
                                                </v-btn>
                                            </template>
                                            Ajouter un prêt
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePickerMenu from '../../widgets/DatePickerMenu.vue';

export default {
    name: 'LoansEditionDialog',

    components: {
        DatePickerMenu
    },

    props: {
        saleId: { type: Number, required: true },
        etablissements: { type: Array, default: () => ([]) },
        natures: { type: Array, default: () => ([]) },
        initLoans: { type: Array, required: true }
    },

    data: () => ({
        isOpen: false,
        loans: []
    }),

    methods: {
        addNewItem() {
            this.loans.push({});
        },

        removeItem(loan, i) {
            if (loan.id) {
                loan.remove = true;
            } else {
                this.loans.splice(i, 1);
            }
        },

        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = {
                    loans: this.loans
                };

                const { success, err } = await this.repos.sales.updateSale(this.saleId, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('reload');
                } else {
                    if (err) {
                        console.error(err);
                        throw new Error();
                    } else {
                        // 
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la mise à jour des prêts',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.loans = JSON.parse(JSON.stringify(this.initLoans));
                this.loans.forEach((d) => this.$set(d, 'remove', false));
            }
        }
    }
};
</script>

<style lang="scss">
tbody.no-hover {
    tr:hover {
        background-color: transparent !important;
    }
}
</style>
