<template>
    <v-menu open-on-hover :close-on-content-click="false" bottom offset-y>
        <template v-slot:activator="{ on }">
            <v-chip class="mr-4" label small :to="`/contacts/${buyer.id}`" target="_blank" v-on="on">
                <v-icon left> fas fa-user </v-icon>
                {{ buyer.name }} {{ buyer.firstname }}
            </v-chip>
        </template>

        <v-card>
            <v-card-title class="subtitle-2 info white--text py-2">
                {{ buyer.name }} {{ buyer.firstname }}
            </v-card-title>

            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title> Email : </v-list-item-title>

                        <v-list-item-subtitle>
                            <template v-if="buyer.email">
                                {{ buyer.email }}
                            </template>
                            <template v-else>
                                <small class="font-italic"> Non renseigné </small>
                            </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="buyer.email">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon @click="copyToClipboard(buyer.email)" color="primary" v-on="on" right small> fas fa-copy </v-icon>
                            </template>
                            Copier
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title> Portable : </v-list-item-title>

                        <v-list-item-subtitle>
                            <template v-if="buyer.mobile">
                                {{ buyer.mobile }}
                            </template>
                            <template v-else>
                                <small class="font-italic"> Non renseigné </small>
                            </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="buyer.mobile">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon @click="copyToClipboard(buyer.mobile)" color="primary" v-on="on" right small> fas fa-copy </v-icon>
                            </template>
                            Copier
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import copyToClipboard from '../../../helpers/copyToClipboard.js';

export default {
    name: 'BuyerChip',

    props: {
        buyer: {
            type: Object,
            required: true
        }
    },

    methods: {
        copyToClipboard
    }
};
</script>