var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-file-signature ")]),_vm._v(" Générer le contrat ")],1)]}}])},[_c('v-list',[_c('OpenContractCheck',{attrs:{"sale":_vm.sale,"code":"contrat_test_papier"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" Visualiser un exemplaire temporaire du contrat (version papier) ")])]}}])}),_c('OpenContractCheck',{attrs:{"sale":_vm.sale,"code":"contrat_test_numerique"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" Visualiser un exemplaire temporaire du contrat (signature électronique) ")])]}}])}),_c('OpenContractCheck',{attrs:{"sale":_vm.sale,"code":"contrat"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" Visualiser la version définitive du contrat ")])]}}])}),_c('SignatureProcessCheck',{attrs:{"sale":_vm.sale},on:{"reload":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" Lancer le process de signature électronique ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }