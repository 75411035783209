var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"axessia-card"},[_c('v-toolbar',{staticClass:"toolbar-underline mb-2 mx-3",attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"primary--text title"},[_vm._v(" Synthèse ")]),_c('v-spacer'),(_vm.canStartSignProcess(_vm.sale))?_c('GenerateContractBtn',{attrs:{"sale":_vm.sale},on:{"reload":function($event){return _vm.$emit('reload')}}}):_vm._e(),(_vm.canRestartSignProcess(_vm.sale))?_c('RestartContractBtn',{attrs:{"sale":_vm.sale},on:{"reload":function($event){return _vm.$emit('reload')}}}):_vm._e()],1),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',{staticClass:"mx-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h6',_vm._g({staticClass:"subtitle-2 grey--text text--darken-2 d-inline-block mr-4"},on),[_vm._v(" Acquéreurs ")])]}}])},[_c('span',[_vm._v(" Les noms et coordonnées des différents acquéreurs ")])])],1),_c('v-col',_vm._l((_vm.sale.buyers),function(buyer,i){return _c('BuyerChip',{key:i,attrs:{"buyer":buyer}})}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h6',_vm._g({staticClass:"subtitle-2 grey--text text--darken-2 d-inline-block mr-4"},on),[_vm._v(" Programme ")])]}}])},[_c('span',[_vm._v(" Le programme et la ville concernés ")])])],1),_c('v-col',[_c('v-chip',{staticClass:"mr-2",attrs:{"to":("/programmes/" + (_vm.sale.program.id)),"label":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-city ")]),_vm._v(" "+_vm._s(_vm.sale.program.title)+" - "),(_vm.sale.program.city)?[_vm._v(" "+_vm._s(_vm.sale.program.city)+" ")]:[_c('span',{staticClass:"font-italic"},[_vm._v(" Ville non renseignée ")])]],2),(_vm.sale.program.presaledate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent","label":"","small":""}},on),[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.sale.program.presaledate))+" ")])]}}],null,false,590315791)},[_vm._v(" Date d'actabilité ")]):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('h6',{staticClass:"subtitle-2 grey--text text--darken-2 d-inline-block mr-4"},[_vm._v(" Statut ")])]),_c('v-col',[_c('v-tooltip',{attrs:{"disabled":!_vm.sale.statusdatelabel,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":_vm.getSaleStatus(_vm.sale.status, 'color'),"label":"","small":"","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.getSaleStatus(_vm.sale.status, 'icon'))+" ")]),_vm._v(" "+_vm._s(_vm.getSaleStatus(_vm.sale.status, 'label'))+" ")],1)]}}])},[_vm._v(" "+_vm._s(_vm.sale.statusdatelabel)+": "+_vm._s(_vm._f("toDate")(_vm.sale.statusdate))+" ")]),_vm._l((_vm.getSaleBadges(_vm.sale)),function(badge){return _c('v-tooltip',{key:badge.code,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ml-2",attrs:{"color":badge.color,"label":"","small":"","dark":""}},on),[_vm._v(" "+_vm._s(badge.label)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(badge.tooltip)+" ")])})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h6',_vm._g({staticClass:"subtitle-2 grey--text text--darken-2 d-inline-block mr-4"},on),[_vm._v(" Acquisition ")])]}}])},[_c('span',[_vm._v(" Les lots concernés par la réservation ")])])],1),_c('v-col',_vm._l((_vm.sale.products),function(product){return _c('ProductChip',{key:product.id,attrs:{"product":product}})}),1)],1)],1)],1),_c('v-row',{staticClass:"mx-2"},[_c('v-col',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"subtitle-2 grey--text text--darken-2 pl-0"},[_vm._v(" Pré-réservation ")]),_c('th',{staticClass:"subtitle-2 grey--text text--darken-2"},[_vm._v(" Signature acquéreur ")]),_c('th',{staticClass:"subtitle-2 grey--text text--darken-2"},[_vm._v(" Signature promoteur ")]),_c('th',{staticClass:"subtitle-2 grey--text text--darken-2"},[_vm._v(" Date envoi SRU ")]),_c('th',{staticClass:"subtitle-2 grey--text text--darken-2"},[_vm._v(" Date limite rétractation ")]),_c('th',{staticClass:"subtitle-2 grey--text text--darken-2"},[_vm._v(" Signature définitive avant ")]),_c('th',{staticClass:"subtitle-2 grey--text text--darken-2"},[_vm._v(" Acte authentique ")]),_c('th',{staticClass:"subtitle-2 grey--text text--darken-2"},[_vm._v(" Date de livraison ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.sale.date))+" ")]),_c('td',[_c('BuyersSignedSignatures',{attrs:{"sale":_vm.sale}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.getSalePromoterSignatureDate(_vm.sale)))+" ")]),_c('td',[_c('BuyersSRUSent',{attrs:{"sale":_vm.sale}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.sale.retractationlimitdate))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.sale.notarylimitdate))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.sale.deeddate))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("toDate")(_vm.sale.deliverydate2))+" ")])])])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }