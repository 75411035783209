<template>
    <v-menu open-on-hover :close-on-content-click="false" bottom offset-y disabled>
        <template v-slot:activator="{ on }">
            <v-chip class="mr-2" label small v-on="on">
                <v-icon small left> {{ getProductTypeIcon(product.type) }} </v-icon>
                {{ product.reference }} - {{ product.typeLabel }} - {{ product.salePrice | toCurrencyString() }}
            </v-chip>
        </template>

        <v-card>
            <v-card-title class="subtitle-2 info white--text py-2">
                {{ product.reference }} {{ product.typeLabel }}
            </v-card-title>

            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title> Référence lot de co-propriété : </v-list-item-title>

                        <v-list-item-subtitle>
                            <template v-if="product.coproreference">
                                {{ product.coproreference }}
                            </template>
                            <template v-else>
                                <small class="font-italic"> Non renseigné </small>
                            </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title> Prix négocié : </v-list-item-title>

                        <v-list-item-subtitle>
                            <template v-if="product.salePrice">
                                {{ product.salePrice | toCurrencyString() }}
                            </template>
                            <template v-else>
                                <small class="font-italic"> Non renseigné </small>
                            </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import productsMixin from '../../../mixins/products.js';

export default {
    name: 'ProductChip',

    mixins: [productsMixin],

    props: {
        product: {
            type: Object,
            required: true
        }
    }
};
</script>