<template>
    <v-dialog v-model="isOpen" width="700px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Edition caractéristiques
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <ValidationObserver ref="observer">
                    <h6 class="subtitle-1 primary--text"> Caractéristiques </h6>

                    <v-row class="ml-5" align="center">
                        <v-col>
                            Taux TVA
                        </v-col>

                        <v-col>

                            <v-select v-model="sale.vat" :disabled="vatSelectDisabled" :readonly="vatSelectDisabled" :items="vatsSelectItems" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined clearable dense>
                                <template v-if="vatDisabledTooltip" v-slot:prepend>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon color="warning" v-on="on"> fas fa-exclamation-triangle </v-icon>
                                        </template>
                                        {{ vatDisabledTooltip }}
                                    </v-tooltip>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row v-if="getConfig('sales.fields.sale_purpose.enabled', true)" class="ml-5" align="center">
                        <v-col>
                            {{ getConfig('sales.fields.sale_purpose.name', 'Destination du bien') }}
                        </v-col>

                        <v-col>
                            <v-select v-model="sale.salepurpose" :items="getParameter('SALE_SALEPURPOSE')" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined clearable dense />
                        </v-col>
                    </v-row>

                    <v-row v-if="getConfig('sales.fields.sale_type.enabled', true)" class="ml-5" align="center">
                        <v-col>
                            {{ getConfig('sales.fields.sale_type.name', 'Type d\'acquisition du bien') }}
                        </v-col>

                        <v-col>
                            <v-select v-model="sale.saletype" :items="getParameter('SALE_SALETYPE').map((str) => str.trim())" :menu-props="{ bottom: true, offsetY: true }" hide-details outlined clearable dense />
                        </v-col>
                    </v-row>

                    <template v-if="isSalePinel(sale)">
                        <h6 class="subtitle-1 primary--text mt-4"> Investissement PINEL </h6>

                        <v-row class="ml-5" align="center">
                            <v-col>
                                Dispositif Pinel
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon class="ml-2" color="primary" small v-on="on"> fas fa-info-circle </v-icon>
                                    </template>
                                    Si réservataire déclare avoir l’intention de demander le bénéfice du dispositif d’investissement locatif Pinel prévu
                                </v-tooltip>
                            </v-col>

                            <v-col>
                                <v-switch v-model="sale.pinel.dispositifPinel" false-value="0" true-value="1" hide-details outlined dense />
                            </v-col>
                        </v-row>

                        <v-row class="ml-5" align="center">
                            <v-col>
                                Pourcentage
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon class="ml-2" color="primary" small v-on="on"> fas fa-info-circle </v-icon>
                                    </template>
                                    Part des frais et commissions dans le prix de revient en %
                                </v-tooltip>
                            </v-col>

                            <v-col>
                                <ValidationProvider v-slot="{ errors, failed }" name="Pourcentage" rules="number|minValue:0|maxValue:10">
                                    <v-text-field v-model="sale.pinel.percentFraisPinel" append-icon="fas fa-percent" :error-messages="errors" :hide-details="!failed" outlined dense />
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-row class="ml-5" align="center">
                            <v-col>
                                Montant des frais
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon class="ml-2" color="primary" small v-on="on"> fas fa-info-circle </v-icon>
                                    </template>
                                    Frais et commissions directs et indirects pris en charge par le RESERVANT
                                </v-tooltip>
                            </v-col>

                            <v-col>
                                <v-text-field v-model="sale.pinel.fraisPinel" append-icon="fas fa-euro-sign" hide-details outlined disabled dense />
                            </v-col>
                        </v-row>
                    </template>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import salesMixin from '../../../mixins/sales.js';

export default {
    name: 'CharacteristicEditionDialog',

    mixins: [salesMixin],

    props: {
        initSale: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false,
        sale: {}
    }),

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = {
                    sale: {
                        vat: this.sale.vat,
                        salepurpose: this.sale.salepurpose,
                        saletype: this.sale.saletype,
                        pinel: this.sale.pinel
                    }
                };

                const { success, err } = await this.repos.sales.updateSale(this.sale.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('reload');
                } else {
                    if (err) {
                        console.error(err);
                        throw new Error();
                    } else {
                        // 
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la mise à jour des caractéristiques',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        vatsSelectItems() {
            const vats = [5.5, 7, 10, 20];

            if (this.isTerrain) {
                vats.unshift(0);
            }
            return vats;
        },

        isTerrain() {
            return ['1', '2'].includes(this.getParameter('PVD_AMENAGEUR')) && (this.initSale.program.type === 1 || this.initSale.product.type === 31);
        },

        hasSomeProductsWithNoPrice() {
            return this.initSale.vat === 20 && this.initSale.products.some((p) => p.main === 1 && !p.priceReducedVat);
        },

        vatSelectDisabled() {
            if (!this.getConfig('sales.enable_vat_modification', true)) {
                return true;
            }

            if (this.isTerrain) {
                return true;
            }

            if (this.hasSomeProductsWithNoPrice) {
                return true;
            }

            return false;
        },

        vatDisabledTooltip() {
            if (!this.getConfig('sales.enable_vat_modification', true)) {
                return 'La modification de la TVA est désactivée';
            }

            if (this.isTerrain) {
                return 'La modification de la TVA est désactivée pour les terrains';
            }

            if (this.hasSomeProductsWithNoPrice) {
                return 'Au moins un des lots ne possède pas de prix tva réduite, vous ne pouvez donc pas modifier la tva';
            }

            if (this.getConfig('sales.enable_partner_vat', false) && this.sale.vat !== 20) {
                return 'Vous avez sélectionné un taux de TVA différent de 20%, veuillez vous rapprocher du service partenariat pour réaliser cette réservation.';
            }

            return '';
        }
    },

    watch: {
        'sale.pinel.percentFraisPinel'() {
            const percent = parseFloat(this.sale.pinel.percentFraisPinel.replace(',', '.'));
            if (!isNaN(percent)) {
                this.sale.pinel.fraisPinel = Math.round(this.getSalePriceTTC(this.sale) * 1.025 * (percent / 100));
            }
        },

        isOpen() {
            if (this.isOpen) {
                this.sale = JSON.parse(JSON.stringify(this.initSale));
            }
        }
    }
};
</script>
