var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Edition caractéristiques ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('ValidationObserver',{ref:"observer"},[_c('h6',{staticClass:"subtitle-1 primary--text"},[_vm._v(" Caractéristiques ")]),_c('v-row',{staticClass:"ml-5",attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Taux TVA ")]),_c('v-col',[_c('v-select',{attrs:{"disabled":_vm.vatSelectDisabled,"readonly":_vm.vatSelectDisabled,"items":_vm.vatsSelectItems,"menu-props":{ bottom: true, offsetY: true },"hide-details":"","outlined":"","clearable":"","dense":""},scopedSlots:_vm._u([(_vm.vatDisabledTooltip)?{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v(" fas fa-exclamation-triangle ")])]}}],null,false,2805676673)},[_vm._v(" "+_vm._s(_vm.vatDisabledTooltip)+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.sale.vat),callback:function ($$v) {_vm.$set(_vm.sale, "vat", $$v)},expression:"sale.vat"}})],1)],1),(_vm.getConfig('sales.fields.sale_purpose.enabled', true))?_c('v-row',{staticClass:"ml-5",attrs:{"align":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.getConfig('sales.fields.sale_purpose.name', 'Destination du bien'))+" ")]),_c('v-col',[_c('v-select',{attrs:{"items":_vm.getParameter('SALE_SALEPURPOSE'),"menu-props":{ bottom: true, offsetY: true },"hide-details":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.sale.salepurpose),callback:function ($$v) {_vm.$set(_vm.sale, "salepurpose", $$v)},expression:"sale.salepurpose"}})],1)],1):_vm._e(),(_vm.getConfig('sales.fields.sale_type.enabled', true))?_c('v-row',{staticClass:"ml-5",attrs:{"align":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.getConfig('sales.fields.sale_type.name', 'Type d\'acquisition du bien'))+" ")]),_c('v-col',[_c('v-select',{attrs:{"items":_vm.getParameter('SALE_SALETYPE').map(function (str) { return str.trim(); }),"menu-props":{ bottom: true, offsetY: true },"hide-details":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.sale.saletype),callback:function ($$v) {_vm.$set(_vm.sale, "saletype", $$v)},expression:"sale.saletype"}})],1)],1):_vm._e(),(_vm.isSalePinel(_vm.sale))?[_c('h6',{staticClass:"subtitle-1 primary--text mt-4"},[_vm._v(" Investissement PINEL ")]),_c('v-row',{staticClass:"ml-5",attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Dispositif Pinel "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","small":""}},on),[_vm._v(" fas fa-info-circle ")])]}}],null,false,1603507233)},[_vm._v(" Si réservataire déclare avoir l’intention de demander le bénéfice du dispositif d’investissement locatif Pinel prévu ")])],1),_c('v-col',[_c('v-switch',{attrs:{"false-value":"0","true-value":"1","hide-details":"","outlined":"","dense":""},model:{value:(_vm.sale.pinel.dispositifPinel),callback:function ($$v) {_vm.$set(_vm.sale.pinel, "dispositifPinel", $$v)},expression:"sale.pinel.dispositifPinel"}})],1)],1),_c('v-row',{staticClass:"ml-5",attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Pourcentage "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","small":""}},on),[_vm._v(" fas fa-info-circle ")])]}}],null,false,1603507233)},[_vm._v(" Part des frais et commissions dans le prix de revient en % ")])],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Pourcentage","rules":"number|minValue:0|maxValue:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"append-icon":"fas fa-percent","error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.sale.pinel.percentFraisPinel),callback:function ($$v) {_vm.$set(_vm.sale.pinel, "percentFraisPinel", $$v)},expression:"sale.pinel.percentFraisPinel"}})]}}],null,false,314809540)})],1)],1),_c('v-row',{staticClass:"ml-5",attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Montant des frais "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","small":""}},on),[_vm._v(" fas fa-info-circle ")])]}}],null,false,1603507233)},[_vm._v(" Frais et commissions directs et indirects pris en charge par le RESERVANT ")])],1),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"fas fa-euro-sign","hide-details":"","outlined":"","disabled":"","dense":""},model:{value:(_vm.sale.pinel.fraisPinel),callback:function ($$v) {_vm.$set(_vm.sale.pinel, "fraisPinel", $$v)},expression:"sale.pinel.fraisPinel"}})],1)],1)]:_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Enregistrer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }