var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-alert',{staticClass:"pa-1",attrs:{"color":_vm.color,"border":"left","elevation":"1","colored-border":"","dense":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[(_vm.isEmpty)?[(_vm.isNoticeDescritive)?[_c('UploadDialog',{attrs:{"sourceId":_vm.sale.id,"sourceType":100,"code":_vm.document.code,"title":_vm.title,"allowTitleEdition":false},on:{"reload":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":_vm.color,"icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v(" fas fa-file-pdf ")])],1)]}}],null,true)},[_vm._v(" Vous devez uploader l'une des notices descriptives du programme ")])]}}],null,true)})]:(!_vm.isUploadAllowed)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":_vm.color,"icon":""}},on),[_c('v-icon',[_vm._v(" fas fa-file-pdf ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.uploadNotAllowedReason)+" ")])]:[_c('UploadDialog',{attrs:{"sourceId":_vm.sale.id,"sourceType":100,"code":_vm.document.code,"title":_vm.title,"allowTitleEdition":false},on:{"reload":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":_vm.color,"icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v(" fas fa-file-pdf ")])],1)]}}],null,true)},[_vm._v(" Ajouter le document ")])]}}],null,true)})]]:[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"href":_vm.documentUrl,"target":"_blank","color":_vm.color,"icon":""}},on),[_c('v-icon',[_vm._v(" fas fa-file-pdf ")])],1)]}}],null,true)},[_vm._v(" Visualiser le document ")])],_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])],2),(!_vm.isEmpty)?_c('v-col',[(_vm.deletable)?[_c('DeleteDocumentDialog',{attrs:{"documentId":parseInt(_vm.document.id),"sourceId":_vm.sale.id,"sourceType":parseInt(_vm.document.sourcetype),"secured":_vm.document.secured === '1',"title":_vm.title},on:{"reload":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"color":"red","icon":"","small":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash ")])],1)]}}],null,true)},[_vm._v(" Supprimer ce document ")])]}}],null,true)})]:[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"float-right",attrs:{"color":"grey","icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.notDeletableReason)+" ")])]],2):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }