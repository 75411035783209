<template>
    <v-dialog v-model="isOpen" width="500">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Création de document
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <ValidationObserver ref="observer">
                    <ValidationProvider v-slot="{ errors, failed }" name="Titre" rules="required">
                        <v-text-field v-model="newTitle" label="Titre" :disabled="!allowTitleEdition" :error-messages="errors" :hide-details="!failed" outlined dense />
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors, failed }" name="Document" :rules="`document:${getConfig('documents.allowed_types', ['application/pdf']).join(',')}`">
                        <v-file-input v-model="file" label="Document" clear-icon="far fa-times-circle" append-icon="fas fa-paperclip" prepend-icon="" :error-messages="errors" :hide-details="!failed" :accept="getConfig('documents.allowed_types', ['application/pdf'])" class="mt-2" chips small-chips show-size outlined dense />
                    </ValidationProvider>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit" color="primary" small>
                    Créer
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'UploadDialog',

    props: {
        sourceId: { type: Number, required: true },
        sourceType: { type: Number, required: true },
        code: { type: String, required: true },
        title: { type: String },
        allowTitleEdition: { type: Boolean, default: true }
    },

    data: () => ({
        isOpen: false,

        newTitle: '',
        file: null
    }),

    methods: {
        open() {
            this.isOpen = true;
        },

        async submit() {
            try {
                const valid = await this.$refs.observer.validate();
                if (!valid) {
                    return;
                }

                this.setLoading(true);

                const body = {
                    title: this.newTitle,
                    code: this.code,
                    sourceId: this.sourceId,
                    sourceType: this.sourceType,
                    secured: true,
                    file: this.file
                };

                const { success, err } = await this.repos.documents.uploadDocument(body);
                if (success) {
                    this.isOpen = false;
                    this.$nextTick(() => {
                        this.$emit('reload');
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de l\'upload du document',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            if (this.title) {
                this.newTitle = this.title;
            }
            this.file = null;
        }
    }
};
</script>
