<template>
    <tr>
        <!-- Description -->
        <td>
            <v-chip label small>
                <v-icon class="mr-3" small> {{ getProductTypeIcon(product.type) }} </v-icon>
                {{ product.reference }}
            </v-chip>
            {{ product.typeLabel }}
            <template v-if="product.roomsLabel"> {{ product.roomsLabel }} </template>
            <template v-if="product.floorLabel"> {{ product.floorLabel }} </template>
        </td>

        <!-- Prix BdV -->
        <td class="text-right white-space-no-wrap">
            {{ product.initialPrice | toCurrencyString() }}
        </td>

        <!-- Prix Remise -->
        <td class="text-right white-space-no-wrap">
            {{ (product.initialPrice - product.salePrice) | toCurrencyString() }}
        </td>

        <!-- Prix négocié -->
        <td class="text-right white-space-no-wrap">
            {{ product.salePrice | toCurrencyString() }}
        </td>
    </tr>
</template>

<script>
import productsMixin from '../../../mixins/products.js';

export default {
    name: 'ProductRow',

    mixins: [productsMixin],

    props: {
        sale: {
            type: Object,
            required: true
        },

        product: {
            type: Object,
            required: true
        },

        vat: {
            type: Number,
            required: true
        }
    }
};
</script>