<template>
    <v-dialog v-model="isOpen" width="30%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Suppression document
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                Voulez-vous vraiment supprimer le document {{ title }} ?
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="error" small> Supprimer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DeleteDocumentDialog',

    props: {
        title: { type: String, required: true },
        documentId: { type: Number, required: true },
        sourceId: { type: Number, required: true },
        sourceType: { type: Number, required: true },
        secured: { type: Boolean, required: true }
    },

    data: () => ({
        isOpen: false
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const body = {
                    sourceId: this.sourceId,
                    sourceType: this.sourceType,
                    secured: this.secured
                };
                const { success, err } = await this.repos.documents.deleteDocument(this.documentId, body);
                if (success) {
                    this.isOpen = false;
                    this.$nextTick(() => {
                        this.$emit('reload');
                    });
                } else {
                    if (err) {
                        console.error(err);
                        throw new Error(err);
                    } else {
                        // 
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression du document',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
