var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Création de document ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":"Titre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"label":"Titre","disabled":!_vm.allowTitleEdition,"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.newTitle),callback:function ($$v) {_vm.newTitle=$$v},expression:"newTitle"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Document","rules":("document:" + (_vm.getConfig('documents.allowed_types', ['application/pdf']).join(',')))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-file-input',{staticClass:"mt-2",attrs:{"label":"Document","clear-icon":"far fa-times-circle","append-icon":"fas fa-paperclip","prepend-icon":"","error-messages":errors,"hide-details":!failed,"accept":_vm.getConfig('documents.allowed_types', ['application/pdf']),"chips":"","small-chips":"","show-size":"","outlined":"","dense":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})]}}])})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.submit}},[_vm._v(" Créer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }